import moment from 'moment'
import type { TypedSchemaError } from 'vee-validate'
import { TASK_STATUS, ACTION_LIST_STATUS } from '~/ts/enum'

import {
  type AvailabilityType,
  type Gender,
  OrganisationType,
  type OrganisationTypeOption,
  type ScaleOption,
  SchedulerType,
  type SchedulerTypeOption,
  type Weekday
} from '~/ts/globals'
import { getAuthCookie } from '~/utils/auth-cookie'
import { LanguageEnum } from '~/ts/enum'

export const APP_NAME: string = import.meta.env.VITE_APP_NAME || 'Ainsights'
export const BROADCAST_URL: string = import.meta.env.VITE_BROADCAST_URL
export const API_URL: string = import.meta.env.VITE_API_URL

export const defaultCountryId: number = 73

export const apiUrl = () => {
  const url = new URL(import.meta.env.VITE_API_URL)
  return url.origin
}

export const AUTH_HEADER = {
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  Authorization: `Bearer ${getAuthCookie()}`,
  Locale: window.localStorage.getItem('lang') || 'en'
}

export const formatMoney = (
  value: number,
  currency: string = 'EUR',
  lang: string = 'nl-NL'
): string => {
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  }).format(value)
}

export const secondsToMinutes = (data: number): number => {
  return Math.floor(data / 60)
}

export const formatDate = (date: Date | string): string => {
  const momentDate = moment(date, 'YYYY-MM-DD')
  if (momentDate.isValid()) {
    return momentDate.format('DD MMM, YYYY')
  }
  return ''
}
export const formatDateTime = (datetime: Date): string => {
  const momentDate = moment(datetime, 'YYYY-MM-DD HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('DD MMM, YYYY HH:mm:ss')
  }
  return ''
}

export const sortIcons = {
  sort: 'fa-sort',
  sortAsc: 'fa-sort-up',
  sortDesc: 'fa-sort-down'
}

export const GENDER: Gender[] = [
  { name: 'Male', value: 'M' },
  { name: 'Female', value: 'F' },
  { name: 'Unknown', value: 'U' }
]

export const WorkFlow = {
  absenceWorkFlow: 'Absence WorkFlow Category'
}

export const Insurance = {
  illness_INSURER_CASE: 'Self-Insurer in case of Illness',
  wga_INSURER_CASE: 'Self-Insurer in case of WGA',
  absence_INSURER_CASE: 'Absence Insurance'
}

export const renderGender = (gender: string): string => {
  if (gender === 'M') {
    return 'Male'
  } else if (gender === 'F') {
    return 'Female'
  } else {
    return 'Unknown'
  }
}

export function chunkArray<T>(array: T[], size: number): T[][] {
  const result: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    const subArray: T[] = array.slice(i, i + size)
    result.push(subArray)
  }
  return result
}

export const transformErrors = <T>(
  errors: TypedSchemaError[]
): Partial<Record<keyof T, string>> => {
  const errorObj: Partial<Record<keyof T, string>> = {}
  for (const [index, error] of errors.entries()) {
    if (error.path) {
      errorObj[error.path as keyof T] = error.errors[index]
    }
  }
  return errorObj
}
export const VANGNET = [
  'Pregnance/Maternity Leave',
  'Illness due to Pregnancy',
  'Illness due to Delivery'
]
export const WAZO = ['Pregnancy/Maternity Leave']

export const parseNumber = (value: number | string | null): number | null => {
  if (value === null || value === '') {
    return null
  }
  return typeof value === 'string' ? parseFloat(value) : value
}

export const scaleOptions: ScaleOption[] = [
  { value: '01:00:00', name: '1 Hour' },
  { value: '00:30:00', name: '30 Minutes' },
  { value: '00:15:00', name: '15 Minutes' },
  { value: '00:10:00', name: '10 Minutes' },
  { value: '00:05:00', name: '5 Minutes' }
]

export const schedulerTypes: SchedulerTypeOption[] = [
  { name: 'Person', value: SchedulerType.Person },
  { name: 'Organisation', value: SchedulerType.Organisation }
]

export const organisationTypes: OrganisationTypeOption[] = [
  { name: 'Client', value: OrganisationType.Client },
  { name: 'Employer', value: OrganisationType.Employer },
  { name: 'Division', value: OrganisationType.Division },
  { name: 'Department', value: OrganisationType.Department }
]
export const availabilities: AvailabilityType = {
  available: true,
  unavailable: true,
  holiday: true
}

export const formatTime = (datetime: string | Date): string => {
  const momentDate = moment(datetime, 'HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('hh:mm A')
  }
  return ''
}

export const badge = (data: string): string => {
  return `<span class="bg-blue-500 text-white py-1 px-2 rounded">${data}</span>`
}

export const renderConcluded = (data: Date): string => {
  return data
    ? `<div class="text-center">
        <span class="inline-block px-2 py-1 text-white bg-green-500 rounded-lg">
          <i class="fas fa-check-circle"></i> Concluded
        </span>
      </div>`
    : `<div class="text-center">-</div>`
}

export const calculateProgress = (deadline: Date): string => {
  const deadlineDate = moment(deadline, 'YYYY-MM-DD')
  const currentDate = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
  const daysDifference = deadlineDate.diff(currentDate, 'days')
  let backgroundColor: string
  let buttonText: string
  if (daysDifference > 5) {
    backgroundColor = 'bg-green-500'
    buttonText = 'On Time'
  } else if (daysDifference < 5 && daysDifference > 0) {
    backgroundColor = 'bg-yellow-500'
    buttonText = 'Warning'
  } else {
    buttonText = 'Overdue'
    backgroundColor = 'bg-red-500'
  }
  return `<button class="text-white text-xs px-3 py-1 rounded-full ${backgroundColor}"><b>${buttonText}</b></button>`
}

export const weekdays: Weekday[] = [
  {
    name: 'sun',
    title: 'sunday',
    value: 0
  },
  {
    name: 'mon',
    title: 'monday',
    value: 1
  },
  {
    name: 'tue',
    title: 'tuesday',
    value: 2
  },
  {
    name: 'wed',
    title: 'wednesday',
    value: 3
  },
  {
    name: 'thur',
    title: 'thursday',
    value: 4
  },
  {
    name: 'fri',
    title: 'friday',
    value: 5
  },
  {
    name: 'sat',
    title: 'saturday',
    value: 6
  }
]

export const getUserNameInitials = (name?: string) => {
  if (!name) return 'U'
  const [firstName, lastName] = name.split(' ')
  return (firstName ? firstName.charAt(0) : '') + (lastName ? lastName.charAt(0) : '')
}

export const formatDateTimeToTime = (datetime: string | Date | null): string => {
  const momentDate = moment(datetime, 'YYYY-MM-DD HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('HH:mm A')
  }
  return ''
}

export const link = (link: string): string => {
  return `
    <router-link
      v-if="link"
      :to="${link}"
    >
      <Icon icon="mdi:link" class="text-xl mr-2" />
      <span class="text-sm">view</span>
    </router-link>
  `
}

export const renderTaskListStatus = (status: string): string => {
  if (status === TASK_STATUS.COMPLETED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-green-500">Completed</span>`
  } else if (status === TASK_STATUS.PENDING) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-yellow-500">Pending</span>`
  } else if (status === TASK_STATUS.CANCELLED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-red-500">Cancelled</span>`
  } else if (status === TASK_STATUS.NO_SHOW) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-500">No Show</span>`
  } else if (status === TASK_STATUS.HALT) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-800">Halt</span>`
  } else {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-800">Unknown</span>`
  }
}

export const renderActionListStatus = (status: string): string => {
  if (status === ACTION_LIST_STATUS.COMPLETED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-green-500">Completed</span>`
  } else if (status === ACTION_LIST_STATUS.PENDING) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-yellow-500">Pending</span>`
  } else if (status === ACTION_LIST_STATUS.CANCELLED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-red-500">Cancelled</span>`
  } else if (status === ACTION_LIST_STATUS.NO_SHOW) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-purple-500">No Show</span>`
  } else if (status === ACTION_LIST_STATUS.DELETED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-800">Halt</span>`
  } else if (status === ACTION_LIST_STATUS.EXECUTED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-green-800">Executed</span>`
  } else if (status === ACTION_LIST_STATUS.INVOICED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-blue-800">Invoiced</span>`
  } else if (status === ACTION_LIST_STATUS.PAID) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-teal-800">Paid</span>`
  } else {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-800">Unknown</span>`
  }
}

export const renderTaskType = (questionnaireType: string): string => {
  if (questionnaireType === 'App\\Models\\Form\\Form') {
    return `Form`
  } else if (questionnaireType === 'App\\Models\\Surveys\\SurveyQuestion') {
    return `Survey`
  } else if (questionnaireType === 'App\\Models\\Template') {
    // Todo: breakdown the template type
    return `Template`
  }
  return ''
}

export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const languageOptions = [
  { name: 'English', value: LanguageEnum.EN },
  { name: 'Dutch', value: LanguageEnum.NL }
]

export const activityTypeList = [
  { id: 'person', name: 'Person' },
  { id: 'organisation', name: 'Organisation' }
]

export const activityProgressList = [
  { id: 'on_time', name: 'On Time' },
  { id: 'warning', name: 'Warning' },
  { id: 'overdue', name: 'Overdue' }
]

export const activityAppointmentTypes = [
  { value: 'on_site', name: 'On Site' },
  { value: 'phone_call', name: 'Phone Call' },
  { value: 'video_call', name: 'Video Call' }
]

export const scheduleTypes = [
  { value: 'contractorActivity', name: 'Schedule contract activity' },
  { value: 'webshopActivity', name: 'Schedule webshop activity' }
]

export const renderRecordType = (recordType: string): string => {
  return recordType
}
