<script setup lang="ts">
import { computed, defineComponent, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { definePage } from 'vue-router/auto'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '~/stores/auth/AuthStore'
import { useLoading } from 'vue-loading-overlay'
import MainNav from '~/components/shared/MainNav.vue'
import NotificationBadge from '~/components/shared/NotificationBadge.vue'
import ThemeToggle from '~/components/shared/ThemeToggle.vue'
import UserNav from '~/components/shared/UserNav.vue'
import { storeToRefs } from 'pinia'
import type { ChatMessage } from '~/ts/interfaces/services/chatService'
import type Echo from 'laravel-echo'
import { useChatNotificationService } from '~/services/chat/useChatNotificationService'
import type { Contact } from '~/ts/interfaces/models/contact.model.interface'
import { useOnlineStatusService } from '~/services/chat/useOnlineStatusService'
import LogoutDialog from '~/components/shared/LogoutDialog.vue'
import { useLogoutService } from '~/services/auth/useLogoutService'
import { useNotificationService } from '~/services/notification/useNotificationService'
import type { Notification } from '~/ts/interfaces/models/notification.model.interface'
import { apiUrl } from '~/utils/constants'
import PermissionManager from '~/permissions'
import TermsAndPrivacyPopup from '~/components/settings/TermsAndPrivacyPopup.vue'

definePage({
  meta: {
    requiresAuth: true
  }
})

defineComponent({
  name: 'DefaultLayout'
})

const loadLayout = ref<boolean>(false)
const { showLogoutDialog } = useLogoutService()
const $loading = useLoading()
const { authUser, kickOut, termsVersion, policyVersion } = storeToRefs(useAuthStore())
const { isLoggedIn, fetchAuthUser, fetchTermsAndPolicyVersion, bearerToken, resetAuthUser } =
  useAuthStore()
const route = useRoute()
const router = useRouter()
const { ping } = useOnlineStatusService()
const intervalId = ref<number | null>(null)

const init = async (): Promise<void> => {
  const loader = $loading.show()

  if (isLoggedIn && !authUser.value) {
    await fetchAuthUser()
    await fetchTermsAndPolicyVersion()
  }

  PermissionManager.setPermissions(authUser.value!.scope ?? [])

  if (!bearerToken) {
    resetAuthUser()
  }

  loadLayout.value = true
  loader.hide()

  intervalId.value = setInterval(() => {
    ping()
  }, 1000 * 60) as unknown as number
}

const chatScreen = computed(() => route.path === '/chat')

const $echo = inject<Echo>('$echo')
const { sendToastNotification } = useChatNotificationService()
const { setOnlineStatus, setUserOnline, setUserOffline } = useOnlineStatusService()
const { allNotifications, notificationCount } = useNotificationService()

onMounted(async () => {
  await init()

  if (!authUser.value) {
    return
  }

  $echo
    ?.join(`notification.${authUser.value?.id}`)
    .listen(
      'Chat\\ChatNotificationEvent',
      (e: { sender_id: string; sender_name: string; receiver_id: string; message: ChatMessage }) =>
        sendToastNotification(e)
    )
    .listen('NotificationBroadcastEvent', (e: { message: Notification }) => {
      allNotifications.value.unshift(e.message)
      notificationCount.value += 1
    })

  $echo
    ?.join(`online-status`)
    .joining(() => {
      setUserOnline(authUser.value?.id!)
    })
    .leaving(() => {
      setUserOffline(authUser.value?.id!)
    })
    .listen(
      'Chat\\OnlineStatusEvent',
      (e: { id: string; online_status: Contact['online_status']; is_online: boolean }) => {
        setOnlineStatus(e)
      }
    )
})

onUnmounted(() => {
  if (!authUser.value) {
    return
  }

  $echo?.leave(`notification.${authUser.value?.id}`)
  $echo?.leave(`online-status`)

  // stop ping
  clearInterval(intervalId.value!)
})

watch(
  () => kickOut.value,
  (value) => {
    if (value) {
      kickOut.value = false
      router.push('/auth/login').then((r) => r)
    }
  }
)

const isTermsAndPolicyAccepted = computed(() => {
  return !!authUser.value?.accepted_policy_version && !!authUser.value?.accepted_terms_version
})
const isAcceptedPolicyVersionMatches = computed(() => {
  return authUser.value?.accepted_policy_version === policyVersion.value
})
const isAcceptedTermsVersionMatches = computed(() => {
  return authUser.value?.accepted_terms_version === termsVersion.value
})
const showTermsAndPrivacyPopup = computed(() => {
  return (
    !isTermsAndPolicyAccepted.value ||
    !isAcceptedPolicyVersionMatches.value ||
    !isAcceptedTermsVersionMatches.value
  )
})
const accepted = () => {
  fetchAuthUser()
}
</script>

<template>
  <div v-if="loadLayout" class="flex flex-col">
    <template v-if="showTermsAndPrivacyPopup">
      <TermsAndPrivacyPopup
        :is-accepted-policy-version-matches="isAcceptedPolicyVersionMatches"
        :is-accepted-terms-version-matches="isAcceptedTermsVersionMatches"
        :is-terms-and-policy-accepted="isTermsAndPolicyAccepted"
        @accepted="accepted"
      />
    </template>
    <template v-else>
      <div class="border-b">
        <div class="flex h-16 items-center px-10">
          <RouterLink to="/" class="flex items-center space-x-2">
            <img :src="`${apiUrl()}/img/logo.png`" alt="logo" class="h-10" />
          </RouterLink>
          <MainNav class="mx-6" />
          <div class="ml-auto flex items-center space-x-4">
            <ThemeToggle />
            <NotificationBadge v-if="$can('notifications.view')" />
            <UserNav />
          </div>
        </div>
      </div>
      <RouterView v-if="chatScreen" />
      <div v-else class="flex-1 space-y-4 p-8 pt-6">
        <RouterView />
        <div id="modals"></div>
      </div>
    </template>
  </div>
  <LogoutDialog :open="showLogoutDialog" />
</template>

<route lang="json">
{
  "name": "default-layout",
  "meta": {
    "requiresAuth": true
  }
}
</route>
