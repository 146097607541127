<script setup lang="ts">
import { defineComponent, onMounted } from 'vue'
import { definePage } from 'vue-router/auto'
import ActionListCount from '~/components/dashboards/widgets/ActionListCount.vue'
import ClientCount from '~/components/dashboards/widgets/ClientCount.vue'
import DepartmentCount from '~/components/dashboards/widgets/DepartmentCount.vue'
import DivisionCount from '~/components/dashboards/widgets/DivisionCount.vue'
import EmployerCount from '~/components/dashboards/widgets/EmployerCount.vue'
import PersonCount from '~/components/dashboards/widgets/PersonCount.vue'
import { useAuthorisationLevelService } from '~/services/auth/useAuthorisationLevelService'
import { useDashboardService } from '~/services/dashboard/useDashboardService'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth/AuthStore'
import DocumentCount from '~/components/dashboards/widgets/DocumentCount.vue'
import AppointmentsWidget from '~/components/dashboards/widgets/AppointmentsWidget.vue'
definePage({
  name: 'main-dashboard',
  meta: {
    requiresAuth: true
  }
})

defineComponent({
  name: 'HomePage'
})

const { authUser } = storeToRefs(useAuthStore())
const { dashboardData, init } = useDashboardService()
const {
  authorizationLevel,
  hasGroupPermission,
  clientAllowedAuthorizationLevels,
  employerAllowedAuthorizationLevels,
  divisionAllowedAuthorizationLevels,
  departmentAllowedAuthorizationLevels,
  personAllowedAuthorizationLevels
} = useAuthorisationLevelService()

onMounted(async (): Promise<void> => {
  if (!authUser.value) return
  await init()
})
</script>

<template>
  <div v-if="!authorizationLevel" class="flex items-center justify-center h-[calc(100vh-16rem)]">
    <p class="font-semibold text-xl">{{ $t('No authorization level is set') }}</p>
  </div>
  <div
    v-else-if="!hasGroupPermission"
    class="flex items-center justify-center h-[calc(100vh-16rem)]"
  >
    <p class="font-semibold text-xl">{{ $t('No permission is set') }}</p>
  </div>
  <div
    v-else-if="dashboardData"
    class="grid gap-5 md:grid-cols-2 my-5 mx-auto"
    :class="['lg:grid-cols-5']"
  >
    <router-link
      :to="`/clients`"
      v-if="clientAllowedAuthorizationLevels.includes(authorizationLevel) && $can('clients.view')"
    >
      <ClientCount :client-count="dashboardData.contractors_count || 0"></ClientCount>
    </router-link>

    <router-link
      :to="`/employers`"
      v-if="
        employerAllowedAuthorizationLevels.includes(authorizationLevel) && $can('employers.view')
      "
    >
      <EmployerCount :employer-count="dashboardData.employers_count || 0"></EmployerCount>
    </router-link>

    <router-link
      :to="`/divisions`"
      v-if="
        divisionAllowedAuthorizationLevels.includes(authorizationLevel) && $can('divisions.view')
      "
    >
      <DivisionCount :division-count="dashboardData.divisions_count || 0"></DivisionCount>
    </router-link>

    <router-link
      :to="`/departments`"
      v-if="
        departmentAllowedAuthorizationLevels.includes(authorizationLevel) &&
        $can('departments.view')
      "
    >
      <DepartmentCount :department-count="dashboardData.departments_count || 0"></DepartmentCount>
    </router-link>

    <router-link
      :to="`/persons`"
      v-if="personAllowedAuthorizationLevels.includes(authorizationLevel) && $can('persons.view')"
    >
      <PersonCount :person-count="dashboardData.persons_count || 0"></PersonCount>
    </router-link>
    <router-link :to="`/action-list`" v-if="authorizationLevel && $can('actionList.view')">
      <ActionListCount
        :action-list-count="dashboardData.action_list_count || 0"
        :title="$t('Action List')"
      ></ActionListCount>
    </router-link>

    <router-link :to="`/activity-list`" v-if="authorizationLevel && $can('activityList.view')">
      <ActionListCount
        :action-list-count="dashboardData.activity_list_count || 0"
        :title="$t('Activity List')"
      ></ActionListCount>
    </router-link>

    <router-link v-if="$can('invoicedActivities.view')" :to="`/invoiced-activities`">
      <ActionListCount
        :action-list-count="dashboardData.invoiced_activity_list_count || 0"
        :title="$t('Invoiced Activities')"
      ></ActionListCount>
    </router-link>

    <router-link
      :to="`/documents`"
      v-if="
        departmentAllowedAuthorizationLevels.includes(authorizationLevel) && $can('documents.view')
      "
    >
      <DocumentCount :document-count="dashboardData.documents_count || 0"></DocumentCount>
    </router-link>
  </div>
  <div v-if="$can('appointments.view')">
    <AppointmentsWidget></AppointmentsWidget>
  </div>
</template>

<route lang="json">
{
  "name": "main-dashboard",
  "meta": {
    "requiresAuth": true
  }
}
</route>
