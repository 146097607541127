import type { LanguageEnum } from '~/ts/enum'
import { hasPermission } from '~/utils/permissions'

export interface ErrorResponse<T> {
  response: {
    status: number
    data: T
  }
}

export interface ErrorMessage {
  errors?: ErrorString
  message: string
}

export interface ValidationErrors {
  errors?: ErrorString
}

export interface AuthenticatorTokenError {
  errors: {
    authenticator_code: string
  }
}

export interface ErrorString {
  [key: string]: string[]
}

export type Gender = {
  name: 'Male' | 'Female' | 'Unknown'
  value: 'M' | 'F' | 'U'
}

export interface Timestamps {
  created_at: Date | null
  updated_at: Date | null
  deleted_at: Date | null
  created_by: string | null
  updated_by: string | null
  deleted_by: string | null
}

export interface ScaleOption {
  name: string
  value: string
}

export interface SchedulerTypeOption {
  name: string
  value: string
}

export enum SchedulerType {
  Person = 'person',
  Organisation = 'organisation'
}

export interface OrganisationTypeOption {
  name: string
  value: string
}

export interface AvailabilityType {
  available: boolean
  unavailable: boolean
  holiday: boolean
}

export interface Weekday {
  name: string
  title: string
  value: number
}

export enum EventType {
  Single = 'singleItem',
  Recurring = 'recurring'
}

export enum OrganisationType {
  Client = 'contractor',
  Employer = 'employer',
  Division = 'division',
  Department = 'department'
}

export type TableTRLink = {
  edit: string
  prefix?: string
  suffix?: string
}

export enum EntityType {
  Client = 'client',
  Employer = 'employer',
  Division = 'division',
  Department = 'department'
}

export type Language = {
  label: string
  value: LanguageEnum.EN | LanguageEnum.NL
}

export interface DocumentType {
  id: string
  name: string
  description: string
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $can: typeof hasPermission
  }
}
